import React from "react";

const Loader = () => {
  return (
    <div className="fixed inset-0 flex flex-col items-center justify-center bg-white z-50">
      <div className="animate-spin rounded-full h-20 w-20 border-t-4 border-[#FF3131] border-solid mb-4"></div>
      <p className="text-lg text-gray-700">Hey, there :)</p>
    </div>
  );
};

export default Loader;
